/*eslint-disable*/
import React from "react";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
//import { globalHistory } from "@reach/router"
//import useIsClient from 'hooks/useIsClient';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Typography from '@material-ui/core/Typography';
// core components
import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import SectionPills from "./Sections/SectionPills.js";
import SectionInterested from "./Sections/SectionInterested.js";

import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import FooterTemplate from "components/Footer/FooterTemplate.js";
import useIsClient from 'hooks/useIsClient';

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

export const query = graphql`
  query {
    bannerImage: imageSharp(resize: {originalName: {eq: "HomeFragranceBiz-Scentsy-Himalayan_Salt_Wax_Warmer.jpeg"}}) {
      gatsbyImageData(quality: 50)
    }
    bannerImageMobile: imageSharp(resize: {originalName: {eq: "HomeFragranceBiz-Scentsy-Himalayan_Salt_Wax_Warmer.jpeg"}}) {
      gatsbyImageData(width: 1200)
    }
  }
`

const useStyles = makeStyles(blogPostsPageStyle);

export default function BlogPostsPage(props) {

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.bannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.bannerImage)
    }
  }

  const classes = useStyles();
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
    if (path.includes("/usa")) {
      path = path.replace("/usa", "");
    }
  const thisUrl = siteMetadata.siteUrl + props.pageContext.locale.path + path;
  return (
    <div>
      <GatsbySeo
        title={"News and announcements" + props.pageContext.locale.appendToTitle}
        description="Our Scentsy blog containing tips + tricks, articles, news, and announcements about Scentsy products and home fragrance."
        canonical={thisUrl}
        language={props.pageContext.locale.isoLanguageCode}
        openGraph={{
          title: "Our Scentsy Blog " + props.pageContext.locale.appendToTitle,
          description: "Our Scentsy blog containing tips + tricks, articles, news, and announcements about Scentsy products and home fragrance.",
          url: thisUrl,
          locale: props.pageContext.locale.ogLanguageCode,
          type: 'website',
          images: [
            {
              url: getSrc(props.data.bannerImage),
              alt: ""
            }]
        }}
      />
      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />
      <Parallax
        image={bannerImage}
        filter="dark"
      >
        <div className={classes.container}>
          <HeaderScentsy />
          <GridContainer justifyContent="center">
            <GridItem md={8} className={classes.textCenter}>
              <Hidden only={['sm','md','lg','xl']} implementation="js">
                <Typography variant="h5" component="h1" className={classes.title}>
                  Our Scentsy Blog
                </Typography>
              </Hidden>
              <Hidden only={['xs']} implementation="js">
                <h1 className={classes.title}>
                  Our Scentsy Blog
                </h1>
              </Hidden>
              <h4 className={classes.subtitle} style={{color: "white"}}>
                Tips + tricks, articles, news, and announcements about Scentsy products and home fragrance.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionPills
            pageContext={props.pageContext}
            bannerImage={props.data.bannerImage}
          />
          <SectionInterested
            pageContext={props.pageContext}
          />
        </div>
      </div>
      <FooterTemplate
        locale={props.pageContext.locale}
      />
    </div>
  );
}