import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";

import { useStaticQuery, graphql, Link } from "gatsby"
import { getSrc, getSrcSet } from "gatsby-plugin-image"
import TagLinks from "components/Tags/TagLinks.js";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested(props) {
  const { pageContext } = props;
  const classes = useStyles();
  const data = useStaticQuery(
      graphql`
        query SectionInterestsedBlogPostsQuery {
          allContentfulBlogPost(limit: 3) {
            edges {
              node {
                title
                slug
                featuredImage {
                  gatsbyImageData
                  title
                }
                excerpt {
                  childMarkdownRemark {
                    excerpt(pruneLength: 150)
                  }
                }
                tags {
                  tagName
                  slug
                }
                publishedDate
              }
            }
          }
        }
      `
    )
  return (
    <div className={classes.section}>
      <h3 className={classes.title + " " + classes.textCenter}>
        You may also be interested in
      </h3>
      <br />
      <GridContainer>
        { /** TODO - add function to set tag text color using enum **/ }
        {data.allContentfulBlogPost.edges.map(edge => {
          const postUrl = pageContext.locale.path + '/blog/' + edge.node.slug + '/';
          return (
            <GridItem xs={12} sm={4} md={4} lg={4} xl={4} key={edge.node.slug}>
              <Card plain blog>
                <CardHeader image plain>
                  <Link to={postUrl}>
                    <img src={
                      getSrcSet(edge.node.featuredImage).split(' ')[0]
                      //getSrc(edge.node.featuredImage)
                      }
                      alt={edge.node.featuredImage.title} />
                  </Link>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      //backgroundImage: "url(" + getSrc(data.cardBackgroundImage) + ")",
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <Info>
                    <h6><TagLinks tags={edge.node.tags} color="black" badge="false"/></h6>
                  </Info>
                  <h4 className={classes.cardTitle}>
                    <Link to={postUrl}>
                      {edge.node.title}
                    </Link>
                  </h4>
                  <p className={classes.description}>
                    {edge.node.excerpt.childMarkdownRemark.excerpt}
                    <Link to={postUrl}> Read More </Link>
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          )
        })}
      </GridContainer>
    </div>
  );
}
