import React from "react";
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { BlogJsonLd } from 'gatsby-plugin-next-seo';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import { useStaticQuery, graphql, Link } from "gatsby"
import { getSrc, getSrcSet } from "gatsby-plugin-image"
import TagLinks from "components/Tags/TagLinks.js";

import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";

const useStyles = makeStyles(sectionPillsStyle);

export default function SectionPills(props) {
  const classes = useStyles();
  const { bannerImage } = props;
  const data = useStaticQuery(
      graphql`
        query BlogPostsQuery {
          allContentfulBlogPostTag {
            edges {
              node {
                slug
                tagName
              }
            }
          }
          allContentfulBlogPost(sort: {order: DESC, fields: publishedDate}) {
            edges {
              node {
                title
                slug
                featuredImage {
                  gatsbyImageData
                }
                excerpt {
                  childMarkdownRemark {
                    excerpt(pruneLength: 150)
                  }
                }
                tags {
                  tagName
                  slug
                }
                publishedDate
              }
            }
          }
        }
      `
    )
  const tagTabs = [{tabButton: "All", tabContent: "", tabSlug: ""}];
  const blogPostsSeoMeta = [];

  data.allContentfulBlogPost.edges.map(edge => {
    const blogPostMeta = {
      headline: edge.node.title,
      image: getSrc(edge.node.featuredImage)
    }
    blogPostsSeoMeta.push(blogPostMeta);
  })

  const { buildTime, siteMetadata } = GetSiteMetadata();
  const thisUrl = siteMetadata.siteUrl + props.pageContext.locale.path + '/blog/'

  return (
    <div className={classes.section}>
      <BlogJsonLd
        url={thisUrl}
        headline={"Our Scentsy Blog " + props.pageContext.locale.appendToTitle}
        images={getSrc(bannerImage)}
        posts={blogPostsSeoMeta}
        dateModified={buildTime}
        authorName='Kathryn Gibson'
        description=''
      />
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
          {
            data.allContentfulBlogPostTag.edges.map(edge => {
              const tab={
                tabButton: edge.node.tagName,
                tabContent: "",
                tabSlug: edge.node.slug
              };
              tagTabs.push(tab);
            })
          }
          <NavPills
            alignCenter
            tabs={tagTabs}
          />
          <div className={classes.tabSpace} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        { /** TODO - add function to ramdomize width of Grid Item xs={12} sm={6} md={6} vs. xs={12} sm={6} md={6} **/ }
        {data.allContentfulBlogPost.edges.map(edge => {
          const postUrl = props.pageContext.locale.path + '/blog/' + edge.node.slug + '/';
          return (
            <GridItem xs={12} sm={6} md={6} key={edge.node.slug}>
              <Card
               raised
               background
               style={{ backgroundImage: "url(" +
                  //getSrc(edge.node.featuredImage)
                  getSrcSet(edge.node.featuredImage).split(' ')[0]
                   + ")" }}
              >
                <CardBody background>
                  <h6 className={classes.category}>
                    <TagLinks tags={edge.node.tags} badge="false"/>
                  </h6>
                  <Link to={postUrl}>
                    <h3 className={classes.cardTitle}>
                      {edge.node.title}
                    </h3>
                  </Link>
                  <p className={classes.category}>
                    {edge.node.excerpt.childMarkdownRemark.excerpt}
                  </p>
                  <Link to={postUrl}>
                    <Button round color="primary">
                      <FormatAlignLeft className={classes.icons} /> Read post
                    </Button>
                  </Link>
                 </CardBody>
               </Card>
            </GridItem>
          )
        })}
      </GridContainer>
    </div>
  );
}
